.container {
  @apply bg-white flex-inherit;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);

  hr {
    @apply text-medium-grey border-medium-grey mx-4 laptop:mx-0;
  }
}

.promoInput {
  input {
    max-height: 34px;
  }
}

.section {
  @apply flex flex-col space-y-lg px-4 py-4;
}

.sectionSmallGap {
  @apply flex flex-col px-4 py-4 space-y-sm
}
