.dateInput{
  @apply border border-dark-grey;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px;
  box-shadow: inset 0 2px 4px rgb(0 0 0 / 10%);
  border-radius: 4px;
  & > input{
    width:32.5%;
    text-align: center;
    outline: none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}