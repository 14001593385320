.left {
  transform: scale(-1, 1);
}

.right {
  //   transform: scale(1, 1);
}

.bottom {
  transform: rotate(90deg);
}

.top {
  transform: rotate(90deg), scale(-1, 1);
}
