ul.list {
  @apply flex flex-row space-x-4 px-6 laptop:px-0 laptop:space-x-xl
          overflow-hidden overflow-x-auto laptop:overflow-x-hidden
          laptop:justify-start;

  flex: 1 1 auto;
  max-width: 100%;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 0;
    background: transparent;
  }
}

ul.mainPageCollection {
  @apply laptop:justify-between
}

li.productGroup {
  @apply flex;
  flex: 0 0 100%;
  display: flex;
  justify-content: center;
  column-gap: 1.4rem;
  @media (min-width: 960px) {
    justify-content: start;
    flex: 0 0 100%;
  }

  .productCardHolder {
    @apply flex;
    flex: 0 0 260px;
    @media (min-width: 960px) {
      flex: 0 0 23%;
    }
  }
}

.carousel {
  @media (max-width: 960px) {
    @apply overflow-hidden;
    padding: 2px;
  }
  & :global(.cws-carousel__item-inner){
    height: 100%;
  }
  .sliderCard{
    height: 100%;
    //@media (min-width: 960px) {
    //  min-width: 288px;
    //}
    //@media (max-width: 955px) {
    //  min-width: 300px;
    //}
    padding: 5px;
  }
}

.carousel_for_one{
  max-width: 312px;
}
.carousel_for_twe{
  max-width: 624px;
}
.carousel_for_free{
  max-width: 936px;
}





