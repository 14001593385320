.button {
  @apply flex flex-1-auto place-items-center place-content-center
    h-8 w-8 text-midnight bg-white overflow-hidden rounded-2xl;

  flex-basis: 32px;
}

.disabled {
  @apply cursor-not-allowed;
  background-color: #ffffff70;
}
