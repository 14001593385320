$tablet-break-point: 600px;

#SSO_Injectable {

  .sso-app-container {
    z-index: auto;
    position: relative !important;
    .sso-auth-btn-wrapper {
      width: 100vw;
      background-color: var(--limited-use-logotype-blue);

      @media only screen and (min-width: $tablet-break-point) {
        width: inherit;
        position: relative !important;
        z-index: auto;
      }

      .sso-auth-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        @media only screen and (min-width: $tablet-break-point) {
          justify-content: flex-start;
          padding-left: 15%;
        }

        .sso-btn-content {
          display: flex !important;
          align-items: center;
          font-family: GTWalsheimPro, sans-serif;

          svg {
            width: 32px;
            padding-right: 10px;
          }
        }
      }
    }
  }
}
