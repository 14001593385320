.box {
  @apply px-2 py-0.5 text-sm font-medium
  focus:outline-none;
  border: solid 1px var(--bermuda);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

//   font: inherit;
  font-weight: 500;
  font-size: 14px;
}

.label {
  @apply text-sm font-medium;
}
