.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(222px, 1fr));
  // grid-template-columns: repeat(auto-fit, 222px);
  width: 100%;
  margin: auto;
  gap: 2.5rem 1.5rem;
  justify-content: center;

  contain: layout;
}
