.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-block-start: 10px;

  .separator {
    width: 1px;
    height: 2.5rem;
    background-color: #d6dfdf;
  }

  .upper {
    transform: rotate(-25.94deg);
  }

  .lower {
    margin-block-start: -5px;
    transform: rotate(-334.06deg);
  }

}

.wrapperMobile {
  display: flex;
  margin: 40px 0;
  .separator {
    width: 50%;
    height:1px;
    background-color: #d6dfdf;
    margin-bottom: 20px;
  }

  .upper {
    transform: rotate(10deg);
  }

  .lower {
    transform: rotate(-10deg) translateX(-4px);
  }
}
