.box {
  @apply flex flex-col gap-y-lg justify-items-center h-full;
}

.title-section {
  @apply flex flex-col px-xxl place-items-center;

  flex: 1 1 auto;
  div:last-child {
    @apply mt-sm;
  }
}

img.img {
  width: auto;
  height: 130px;
  object-fit: contain;
  margin: auto;

  transition: all 0.35s ease;

  &:hover {
    opacity: 0.75;
  }

  &.not-loaded {
    opacity: 0;
  }
}
.defaultImage{
  display: flex;
  justify-content: center;
}
@keyframes loading-keyframes {
  from,
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  to,
  100% {
    opacity: 1;
  }
}

p.title {
  @apply font-medium cursor-pointer overflow-hidden overflow-ellipsis;
}

button.details {
  @apply text-buoy-link normal-case text-base leading-5.5 font-medium;
}
