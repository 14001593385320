/* Tailwind system imports */
@tailwind base;

@tailwind components;

@tailwind utilities;

@tailwind variants;

@import "~cms-web-styleguide/dist/assets/styles/abstracts/_variables.scss";

/* Root styles - colors */
:root {
  --limited-use-logotype-blue:#{$cws-color-logotype-blue};
  --logotype-red: #{$cws-color-logotype-red};
  --midnight: #{$cws-color-midnight};
  --group-grey: #{$cws-color-group-grey};
  --group-light-gray: #fbfbfb;
  --group-dark-gray: #ddd;
  --buoy: #{$cws-color-buoy};
  --buoy-light: #{$cws-color-buoy-light};
  --dark-grey: #{$cws-color-dark-grey};
  --action-light: #{$cws-color-action-light};
  --disabled-gray: #ccd8da;
  --placeholder-grey: #959595;
  --light-midnight: #466b77;
  --pool-light-activities: #{$cws-color-pool-light};
  --sunset-rest: #{$cws-color-sunset};
  --shuttle-lemon: #{$cws-color-lemon};
  --clicked-buoy: #00d3b4;
  --action: #{$cws-color-action};
  --buoy-link:#{$cws-color-buoy-link};
  --clubone-blue: #9dc3dd;
  --dark-midnight: #012a35;
  --bluegray: #a4bec3;
  --white: #{$cws-color-white};
  --shuttle-green: #{$cws-color-shuttle-green};
  --medium-grey:#{$cws-color-medium-grey};
  --seafoam: #{$cws-color-seafoam};
  --selection-color: #00a3da;
  --notice: #{$cws-color-notice};
  --bundle: #ff6319;
  --info:#{$cws-color-info};
  --error:#{$cws-color-error};
  --twilight:#{$cws-color-twilight};
}
@font-face {
  font-family: 'GTWalsheimPro';
  font-style: oblique;
  font-weight: 400;
  font-display: optional;
  src: url('/fonts/gt-walsheim-pro-regular-oblique-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'GTWalsheimPro';
  font-style: oblique;
  font-weight: 500;
  font-display: optional;
  src: url('/fonts/gt-walsheim-pro-medium-oblique-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'GTWalsheimPro';
  font-style: oblique;
  font-weight: 600;
  font-display: optional;
  src: url('/fonts/gt-walsheim-pro-bold-oblique-webfont.woff2') format('woff2');
}

@font-face {
  font-family: 'GTWalsheimPro';
  font-style: normal;
  font-weight: 400;
  font-display: optional;
  src: url('/fonts/GT-Walsheim-Pro-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'GTWalsheim';
  font-style: normal;
  font-weight: 200;
  font-display: optional;
  src: url('/fonts/GT-Walsheim-Pro-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'GTWalsheimPro';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url('/fonts/GT-Walsheim-Pro-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'GTWalsheim';
  font-style: normal;
  font-weight: 500;
  font-display: optional;
  src: url('/fonts/GT-Walsheim-Pro-Medium.woff2') format('woff2');
}

@font-face {
  font-family: 'GTWalsheimPro';
  font-style: normal;
  font-weight: 600;
  font-display: optional;
  src: url('/fonts/GT-Walsheim-Pro-Bold.woff2') format('woff2');
}
/* Fonts imports end */

html,
body {
  padding: 0;
  margin: 0;

}

@media (prefers-color-scheme: dark) {
}

a {
  color: inherit;
  text-decoration: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  border-color: inherit;
}

/* Selection styles */
::selection {
  background: var(--selection-color);
  color: var(--white);
  text-shadow: none;
}

/**
 * Source: https://github.com/vercel/commerce/blob/main/assets/chrome-bug.css
 *
 * Chrome has a bug with transitions on load since 2012!
 *
 * To prevent a "pop" of content, you have to disable all transitions until
 * the page is done loading.
 *
 * https://lab.laukstein.com/bug/input
 * https://twitter.com/timer150/status/1345217126680899584
 */
body.loading * {
  transition: none !important;
}


.grecaptcha-badge {
  visibility: hidden;
}
