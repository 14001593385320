.rangeContainer {
  --thumb-demension: 24px;
  --input-length: 100%;

  padding: 1rem 0;
  position: relative;

  @mixin range-track {
    height: 5px;
  }

  @mixin range-thumb {
    height: var(--thumb-demension);
    width: var(--thumb-demension);
    background-color: var(--action);
    cursor: pointer;
    border-radius: 50%;
    pointer-events: auto;
    margin-top: -10px;
    border: 0;
  }

  input[type=range] {
    width: var(--input-length);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;
    margin: auto;
    background-color: transparent;
    pointer-events: none;
    position: absolute;
    inset-inline: 0;
    inset-block: 0;
  }

  input[type=range]::-moz-range-track {
    -moz-appearance: none;
    @include range-track
  }

  input[type=range]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    @include range-track
  }

  input[type=range]::-ms-track {
    appearance: none;
    @include range-track
  }

  input[type=range]::-moz-range-thumb {
    -moz-appearance: none;
    @include range-thumb
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include range-thumb
  }

  input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    @include range-thumb
  }

  input[type=range]::-ms-thumb {
    appearance: none;
    @include range-thumb
  }

  .sliderTrack {
    width: var(--input-length);
    height: 8px;
    margin: auto;
    border-radius: 5px;
  }
}
