.edge-shadow {
  @apply absolute bottom-0 left-0 right-0;
  height: 44px;
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), var(--white));
}

.text-container {
  @apply relative overflow-hidden;
}

.button {
  @apply flex text-buoy-link place-items-center;
  padding: 0;
}

.arrow {
  @apply ml-xs;
  transform: rotate(90deg);
}

.arrow-up {
  transform: rotate(-90deg);
}
