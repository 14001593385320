.button {
  @apply flex h-12 w-8 rounded-md items-center justify-center;
  flex: 0 0 32px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  max-width: 100%;
}

.disabled {
  @apply cursor-not-allowed;
  opacity: 0.5;
}
