.container {
  @apply device600:bg-white flex-inherit;
  @media only screen and (min-width: 600px) {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
  }


  hr {
    @apply text-medium-grey border-medium-grey device600:mx-4 ;
  }
}

.section {
  @apply flex flex-col device600:px-4 py-4 space-y-lg;
}

.priceSection {
  @apply space-y-0;
  .litrePrice {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.gender{
  @apply flex justify-between device600:px-4 py-4 ;
  & * {
    margin: 0;
  }
}
.hr {
}

hr.hr-bold {
  @apply border-midnight border-t-2;
}

.parameter {
  @apply flex flex-row justify-between items-baseline;
}

.sizes-container {
  @apply flex flex-row items-end;
  & > div {
    @apply items-center;
    flex: 1 0 auto;
  }
}

.vertical-rule {
  @apply h-full w-px self-stretch flex-0-auto bg-medium-grey;
}

.tab-button {
  @apply flex flex-row py-3.5 items-center justify-center
  text-base leading-5 font-medium text-midnight cursor-pointer;
}

.tab-button-shadow {
  box-shadow: inset 0 -10px 10px -10px #00000010;
}

.input-group {
  @apply flex flex-col space-y-2;
}


  .readLeft{
    @apply text-left relative;
    &:before{
      content: ' ';
      position: absolute;
      inset: 0 98% 0 0;
      @apply bg-notice
    }
  }
  .readRight {
    @apply text-right relative;
    &:before{
      content: ' ';
      position: absolute;
      inset: 0 0 0 98%;
      @apply bg-notice
    }
  }

