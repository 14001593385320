.container {
  @apply flex flex-col;
}

.input-wrapper {
  @apply flex flex-row flex-1-auto
  border-dark-grey border;
  border-radius: 4px;
}

.input {
  &::placeholder {
    @apply font-normal;
  }
}

.label {
  @apply mb-sm;
}

.box-shadow {
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.2);
}
