
.radio {
  box-sizing: content-box;
  @apply flex h-5 w-5 rounded-full border border-dark-grey cursor-pointer;

  &::before {
    @apply block w-2.5 h-2.5 rounded-full m-auto bg-midnight;
    content: '';
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.2);
  appearance: none;
  background-color: var(--white);
  margin: 0;
}

.container {
  @apply flex flex-row items-center cursor-pointer;
}

.label {
  @apply ml-2 font-medium text-midnight leading-5 cursor-pointer;
}

.checked {
  @apply bg-midnight;
  &::before {
    transform: scale(0.9);
    background-color: var(--white);
  }
}
