.ssoWrapper {
  min-width: 10%;
  //position: fixed;
  top: 0;
  right: 0;
  z-index: 99;

  @media only screen and (max-width: 600px) {
    position: inherit;
    top: 0;
    right: 0;
  }
}

/* SSR header - paints the initial header - component lib has some odd opacity:0 there. */
.headerNavPreloaded {
  opacity: 1 !important;
}

.header {
  @media only screen and (max-width: 600px){
    & :global(.cws-header){
      margin-top: 40px;
    }
  }

}
.headerMenu{
  & :global(.cws-header__megamenu){
    opacity: 0;
  }
}
.headerCategoryWrapper{
  overflow: auto;
  max-height:calc(100vh - 130px);
  &::-webkit-scrollbar {
    display: none;
  }
}
.navItem{
  &:before{
    content: ' ';
    position: absolute;
    width: calc(40px + 100%);
    transform: translate(-10%,-50%);
    height: 70px;
  }
}
.rightBlock{

  @media (min-width: 800px) {
    & :global(.cws-header__right){
      margin-right: 60px;
    }
  }
  @media (min-width: 1024px) {
    & :global(.cws-header__right){
      margin-right: 40px;
    }
  }
  @media (min-width: 1288px) {
    & :global(.cws-header__right){
      margin-right: 20px;
    }
  }
}


