.button-base {
  @apply font-medium uppercase text-base outline-none
  focus:outline-none shadow-none focus:shadow-none
  border-none;
  line-height: 1.25;
}

.size-sm {
  @apply px-lg py-sm text-sm leading-5.5;
}

.size-md {
  @apply px-xl py-md text-base leading-5.5 font-medium;
}

$colors: (
  'primary': var(--midnight),
  'secondary': var(--action),
);

/* Outline variant for each color generator */
@each $color-key, $color-value in $colors {
  .outline-#{$color-key} {
    color: $color-value;
    border: 1px solid $color-value;

    &:hover {
      background-color: var(--group-grey);
    }
  }

  .text-#{$color-key} {
    color: $color-value;
  }
}

.fill-primary {
  background-color: map-get($colors, 'primary');
  color: var(--white);
}

.fill-secondary {
  background-color: map-get($colors, 'secondary');
  color: map-get($colors, 'primary');
}

/* Disables text transforming */
.no-uppercase {
  text-transform: none;
}
